import React from 'react'

const ErrorPage = () => {
	if (typeof window !== 'undefined') {
		throw Error("Error page!")
	}
	return (
		<div>
			This will be an error
		</div>
	);
}

export default ErrorPage
